/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-text'

// Vue@Properties
const props = {
  mjml: Object
}
// Vue@Properties

// Vue component syntax
export default {
  name,
  props
}
